import React from 'react';
import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';
import { baseFontStyle } from 'components/typography';

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  textarea {
    resize: none;
  }
`;

const Label = styled.label`
  ${baseFontStyle};
  opacity: 0.5;
  margin-bottom: 12px;
`;

const StyledInput = styled.input`
  position: relative;
  border: none;
  font-size: 21px;
  font-weight: 500;
  line-height: 33px;
  opacity: 0.5;
  border-bottom: 2px solid #ebefee;

  &::placeholder {
    opacity: 1;
    color: #000;
  }

  &:focus {
    opacity: 1;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 26px;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  label {
    margin-right: 10px;
  }
`;

const ErrorMessage = styled.span`
  ${baseFontStyle};
  color: #ec5990;
  position: absolute;
  bottom: -25px;
  left: 2px;
  right: 0;
  text-align: right;
  @media ${mediaMin(breakpoint.desktop)} {
    text-align: left;
  }
`;

interface InputProps {
  label: string;
  placeholder?: string;
  type?: 'text' | 'textarea' | 'tel' | 'email';
  name?: string;
  error: string | undefined;
}

const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  ({ label, placeholder, type = 'text', name, error }, ref) => {
    return (
      <InputContainer>
        <LabelWrapper>
          <Label>{label}</Label>
        </LabelWrapper>
        <StyledInput
          {...(type === 'textarea' ? { as: 'textarea', rows: 3 } : { type })}
          name={name}
          placeholder={placeholder}
          ref={ref}
          required
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </InputContainer>
    );
  },
);

export default Input;
